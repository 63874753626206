@import '~shared-modules/styles/constants';

@mixin row($margin-top: 10px) {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: $margin-top;
  margin-bottom: 10px;
  height: 35px;
}

.wrapper {
  color: var(--color-text-lightgray);
  font-size: 13px;
  padding: 1px 16px;
  background-color: var(--color-table-header);
}

.title {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.buySellRow {
  @include row(10px);
}

.itemClassName {
  padding: 0 !important; // stylelint-disable-line
  width: 56px;
}

.countRow {
  @include row;
}

.datePickerRow {
  @include row;
}

.timePickerRow {
  @include row;
}

.necessaryMarginRow {
  @include row;
}

.necessaryMargin {
  font-family: var(--font-family-ascii);
  font-weight: bold;
  letter-spacing: 0.5px;
}

.limitPriceRow {
  @include row;
}

.stopPriceRow {
  @include row;
}

.expirationTypeRow {
  @include row;
}

.switch {
  @include row;

  width: 100%;
}

.markupsWarningRow {
  width: 306px;
  height: 84px;
  background-color: #451c0d66;
  padding: 12px;
  user-select: none;

  .inner {
    color: #fb4b53;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0;
    vertical-align: middle;
    display: inline;
    word-break: break-all;
  }

  .button {
    color: #fb4b53;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-thickness: 0%;
  }
}
