@import '~shared-modules/styles/constants';

.logos {
  display: flex;
  cursor: pointer;
}

.triautoLogo {
  height: 26px;
  padding-right: 8px;
}
