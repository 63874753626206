@import '~shared-modules/styles/constants';

@mixin row($margin-top: 10px) {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: $margin-top;
  margin-bottom: 10px;
  height: 35px;
}

.allWrapper {
  padding: 0 1px;
}

.tableWrapper {
  box-shadow: 0 5px 0 0 var(--color-bg-normal);
  width: 96%;
}

.wrapper {
  color: var(--color-text-lightgray);
  font-size: 13px;
  padding: 1px 16px;
  background-color: var(--color-table-header);
}

.itemClassName {
  padding: 0 !important; // stylelint-disable-line
  font-size: 14px;
  width: 64px;
}

.countRow {
  @include row;
}

.switch {
  @include row;

  width: 100%;
}

.buySellTable {
  font-size: 13px;
  color: $webWhite;
  width: -webkit-fill-available;

  td {
    border: 2px none;
    padding: 3px;
  }

  *>tr>td:first-child {
    text-align: right;
  }

  *>tr>td:last-child {
    text-align: left;
  }
}

.buySellTitle {
  text-align: center;
  font-size: 14px;
}

.buySellLabel {
  text-align: center;
  font-size: 12px;
}

.avgTitle {
  text-align: center;
  font-size: 12px;
}

.buySellValue {
  font-family: var(--font-family-ascii);
  font-size: 15px;
  width: 92px;
}

.avgValue {
  font-family: var(--font-family-ascii);
  font-size: 15px;
  width: 92px;
}

.makeupsWarningRow {
  width: 306px;
  height: 84px;
  background-color: #451c0d66;
  padding: 12px;
  user-select: none;

  .inner {
    color: #fb4b53;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0;
    vertical-align: middle;
    display: inline;
    word-break: break-all;
  }

  .button {
    color: #fb4b53;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-thickness: 0%;
  }
}
